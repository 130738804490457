// Quasar SCSS (& Sass) Variables
// --------------------------------------------------
// To customize the look and feel of this app, you can override
// the Sass/SCSS variables found in Quasar's source Sass/SCSS files.

// Check documentation for full list of Quasar variables

// Your own variables (that are declared here) and Quasar's own
// ones will be available out of the box in your .vue/.scss/.sass files

// It's highly recommended to change the default colors
// to match your app's branding.
// Tip: Use the "Theme Builder" on Quasar's documentation website.

// Colors.

$primary:   #1e1e1e;
$secondary: #4C4477;
$accent:    #8C32E6;

$dark:  #403a50;
$light: #F5F5F7;

$positive: #00B9FF;
$negative: #E5083B;
$info:     #2151F3;
$warning:  #F2A813;

$shadow-color: $primary;
$separator-color: $secondary;

$light-accent: #FFBE59;
$link-color:   #4C4477;

// Scroll.

$scroll-weight: 4px;

// Radius.

$card-radius: 1rem;

// Shadow.

$card-shadow: rgba(0, 0, 0, 0.35) 0 25px 20px -20px;

// Spacing.

$space-base: 32px;
$space-x-base: $space-base;
$space-y-base: $space-base;

$space-none: (
  x: 0,
  y: 0,
);

$space-xs: (
  x: ($space-x-base * .25),
  y: ($space-y-base * .25),
);

$space-ss: (
  x: ($space-x-base * .35),
  y: ($space-y-base * .35),
);

$space-sm: (
  x: ($space-x-base * .5),
  y: ($space-y-base * .5),
);

$space-mm: (
  x: ($space-x-base * .63),
  y: ($space-y-base * .63),
);

$space-md: (
  x: $space-x-base,
  y: $space-y-base,
);

$space-dd: (
  x: ($space-x-base * 1.1),
  y: ($space-y-base * 1.1),
);

$space-lg: (
  x: ($space-x-base * 1.5),
  y: ($space-y-base * 1.5),
);

$space-gg: (
  x: ($space-x-base * 2.2),
  y: ($space-y-base * 2.2),
);

$space-xl: (
  x: ($space-x-base * 3),
  y: ($space-y-base * 3),
);

$spaces: (
  'none': $space-none,
  'xs': $space-xs,
  'ss': $space-ss,
  'sm': $space-sm,
  'mm': $space-mm,
  'md': $space-md,
  'dd': $space-dd,
  'lg': $space-lg,
  'gg': $space-gg,
  'xl': $space-xl,
);

// Spacer.

$spacer-xs: map_get($space-xs, 'x');
$spacer-ss: map_get($space-ss, 'x');
$spacer-sm: map_get($space-sm, 'x');
$spacer-mm: map_get($space-mm, 'x');
$spacer-md: map_get($space-md, 'x');
$spacer-dd: map_get($space-dd, 'x');
$spacer-lg: map_get($space-lg, 'x');
$spacer-gg: map_get($space-gg, 'x');
$spacer-xl: map_get($space-xl, 'x');

// Block Spacing.

$bs-base: map-get($space-lg, x) * 1.4;

$bs-x: $bs-base * 0.7;
$bs-t: $bs-base * 0.7;
$bs-b: $bs-base * 1.4;

$bs-xs-x: $bs-x * .5;
$bs-xs-t: $bs-t * .7;
$bs-xs-b: $bs-b * .7;

// Symmetric Block Spacing.

$sbs-y: $bs-t;
$sbs-x: $bs-x;

$sbs-xs-y: $bs-xs-t;
$sbs-xs-x: $bs-xs-x;

// Inner Block Spacing.

$ibs-base: $bs-base * .45;

$ibs-y: $ibs-base;
$ibs-x: $ibs-base;

$ibs-xs-y: $ibs-base * .8;
$ibs-xs-x: $ibs-base * .8;

// Typography.

$font-size: 16px;
$font-color: #292633;

$typography-font-family: 'Gotham Pro', Helvetica, sans-serif;

$h1: (
  size: 1.8rem,
  line-height: 2.4rem,
  letter-spacing: -.00833em,
  weight: 500,
);

$h2: (
  size: 1.7rem,
  line-height: 2.3rem,
  letter-spacing: -.00833em,
  weight: 500,
);

$h3: (
  size: 1.4rem,
  line-height: 1.8rem,
  letter-spacing: normal,
  weight: 500,
);

$h4: (
  size: 1.1rem,
  line-height: 1.3rem,
  letter-spacing: .00735em,
  weight: 500,
);

$h5: (
  size: 1.1rem,
  line-height: 1.45rem,
  letter-spacing: normal,
  weight: 500,
);

$h6: (
  size: 1rem,
  line-height: 1.3rem,
  letter-spacing: .0125em,
  weight: 500,
);


$h1-xs: (
  size: 1.4rem,
  line-height: 2rem,
  letter-spacing: -.00833em,
  weight: 600,
);

$h2-xs: (
  size: 1.3rem,
  line-height: 1.9rem,
  letter-spacing: -.00833em,
  weight: 500,
);

$h3-xs: (
  size: 1.2rem,
  line-height: 1.85rem,
  letter-spacing: normal,
  weight: 500,
);

$h4-xs: (
  size: 1.15rem,
  line-height: 1.75rem,
  letter-spacing: .00735em,
  weight: 500,
);

$h5-xs: (
  size: 1.05rem,
  line-height: 1.4rem,
  letter-spacing: normal,
  weight: 500,
);

$h6-xs: (
  size: 1rem,
  line-height: 1.25rem,
  letter-spacing: .0125em,
  weight: 500,
);

$subtitle1: (
  size: 1.2rem,
  line-height: 1.75rem,
  letter-spacing: .00937em,
  weight: 500,
);

$subtitle2: (
  size: 1rem,
  line-height: 1.375rem,
  letter-spacing: .00714em,
  weight: 500,
);

$body1: (
  size: 1rem,
  line-height: 1.5rem,
  letter-spacing: .03125em,
  weight: 500,
);

$body2: (
  size: .9rem,
  line-height: 1.25rem,
  letter-spacing: .01786em,
  weight: 500,
);

$overline: (
  size: .81rem,
  line-height: 2rem,
  letter-spacing: .16667em,
  weight: 500,
);

$caption: (
  size: .81rem,
  line-height: 1.25rem,
  letter-spacing: .03333em,
  weight: 500,
);

// Buttons.

$button-font-size: 16px;
$button-padding: 4px 24px;

// Alpha pattern.

$alpha-pattern-color: #e2e2e2;
$alpha-pattern-size: 32px 32px;
$alpha-pattern-image: url('data:image/svg+xml,\
  <svg xmlns="http://www.w3.org/2000/svg" width="400" height="400" fill-opacity=".9" >\
    <rect x="200" width="200" fill="white" height="200" />\
    <rect y="200" width="200" fill="white" height="200" />\
  </svg>'
);

// Brand gradient.

$brand-gradient: linear-gradient(58deg, rgba(62, 125, 244, 1) 15%, rgba(228, 8, 60, 1) 85%);

// Layout Header Height.

$page-header-height: 84px;
$page-header-height-sm: 64px;
$page-header-height-xs: 58px;

// Mixins.

@import "mixins";
